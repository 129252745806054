export default async function getMenuData() {
  return [
    {
      category: true,
      title: '누리 네트워크',
    },
    {
      title: '대시보드',
      key: 'delta',
      icon: 'fe fe-home',
      url: '/dashboard/delta',
    },
    {
      title: '주문하기',
      key: 'order',
      icon: 'fe fe-shopping-cart',
      url: '/dashboard/pricing-tables',
    },
    // {
    //   title: 'admin',
    //   key: 'admin',
    //   icon: 'fe fe-database',
    //   url: '/dashboard/admin',
    //   roles: ['admin'], // set user roles with access to this route
    // },
    {
      title: 'admin2',
      key: 'admin2',
      icon: 'fe fe-database',
      url: '/dashboard/admin2',
      roles: ['admin'], // set user roles with access to this route
    },
    {
      title: '유저추가',
      key: 'adduser',
      icon: 'fe fe-database',
      url: '/dashboard/adduser',
      roles: ['admin'], // set user roles with access to this route
    },
    {
      title: '총용량',
      key: 'status',
      icon: 'fe fe-database',
      url: '/dashboard/status',
      roles: ['admin'], // set user roles with access to this route
    },
    {
      title: '유저찾기',
      key: 'findUser',
      icon: 'fe fe-database',
      url: '/dashboard/findUser',
      roles: ['admin'], // set user roles with access to this route
    },
    // {
    //   title: 'nice',
    //   key: 'nice',
    //   icon: 'fe fe-database',
    //   url: '/dashboard/nice',
    //   roles: ['admin'], // set user roles with access to this route
    // },
  ]
}
